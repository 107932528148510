.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-img {
  max-height: calc(100vh - calc(10px + 2vmin));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}